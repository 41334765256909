import React from 'react';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion'


class Product extends React.Component {
    render(){

        //Animation sur les images
        const imgAnim = {
            initial: {
                opacity: 0.4,
                x: -100,
            },
            visible: {
                opacity: 1,
                x: 0,
            }
        }

        //Separation entre les articles
        const separation = this.props.product.separation;

        return(
            <>
                <article className={"shop_content border_bottom " + this.props.product.position} >
                    <motion.img 
                        className="shop_img" 
                        src={this.props.product.image} 
                        alt={this.props.product.alt} 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <div className="shop_text text_center">
                        <h2>{this.props.product.category}</h2>
                        <h3>{this.props.product.name}</h3>
                        <p>{this.props.product.description}</p>
                        <p className="text_bold">Prix : {this.props.product.price}€</p> 
                        <Link to="/contact" className="btn_shop">Commandez</Link> 
                    </div>    
                </article>
                {separation ? <hr className="shop_break"/> : null }
            </>
        )
    }

}


export default Product