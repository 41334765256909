import React from 'react';
import Footer from './Footer';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion'


const About = () => {

    //Animation de transition des pages
    const variants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.5 },
            x: -800
        },
        visible: {
            opacity: 1,
            x: 0
        },
        exit: {
            opacity: 0.4,
            transition: { duration: 0.3},
            x: 800
        }
    }

    //Animation sur les images
    const imgAnim = {
        initial: {
            opacity: 0.4,
            x: -100,
        },
        visible: {
            opacity: 1,
            x: 0,
        }
    }

    const transition = {
        ease: [.03,.87,.73,.9],
        duration: .6
    }
    
    return(
        <>
            <motion.main
                className="about" 
                initial="initial" 
                animate="visible" 
                exit="exit" 
                variants={variants}
                transition={transition}
            >
                <h1 className="about_title">Accueil</h1>
                <article className="about_content">
                    <motion.img 
                        className="about_img" 
                        src="./images/orangette-chocolat-lait_etoile-noel-greedies.jpg" 
                        alt="Orangettes au chocolat au lait" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <div className="about_text">
                        <p>
                            Greedies vous propose pour la 3ème édition, sa boutique éphémère de Noël. <br/>
                            Cette année, plus de choix vous est proposé pour ravir toutes les gourmandes et tous les gourmands. <br/>
                            À déguster ou à offrir !
                        </p> 
                        <Link className="btn_about" to="/shop">Boutique éphémère</Link> 
                    </div>    
                </article>
                <article className="about_content article_left">
                    <motion.img 
                        className="about_img" 
                        src="./images/emballage-chocolat-noel-etoile-greedies.jpg" 
                        alt="Ballotin de chocolats de Noël en forme d'étoile" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <div className="about_text">
                        <p>
                            L'Etoile de Noël est de retour en cette période des fêtes.<br/>
                            Même format mais en 3 compositions différentes :
                        </p> 
                        <ul>
                            <li>Orangettes au chocolat noir,</li>
                            <li>Orangettes au chocolat au lait,</li>
                            <li>Assortiment de chocolats noir, lait, blond, blanc et fraise.</li>
                        </ul>
                    </div>
                </article>
                <article className="about_content">
                    <motion.img 
                        className="about_img" 
                        src="./images/truffe-chocolat-blanc-coco_boule-coco_decoration-sapin-noel-greedies.jpg" 
                        alt="Truffes coco et chocolat blanc dans leur boite en forme de boule de Noël" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <div className="about_text">
                        <p>
                            Un nouveau ballotin fait son entrée : la boule de Noël garnie de truffes, à accrocher sur le sapin. <br/>
                            Disponible en 2 parfums :
                        </p> 
                        <ul>
                            <li>Truffes chocolat blanc, coco,</li>
                            <li>Truffes chocolat au lait, praliné.</li>
                        </ul>
                    </div>
                </article>
                <article className="about_content article_left">
                    <motion.img 
                        className="about_img" 
                        src="./images/baba-limoncello_noel-greedies.jpg" 
                        alt="Mini-babas au Limoncello dans leur pot en verre" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <p className="about_text">
                        En exclusivité pour Noël, les pots individuels de mini-babas au Limoncello, à consommer avec ou sans modération !
                    </p> 
                </article>
                <article className="about_content">
                    <motion.img 
                        className="about_img" 
                        src="./images/fleur-de-sel-guerande_agriculture_biologique_artisanal_non-raffine_noel-greedies.jpg" 
                        alt="Fleur de sel de Guérande AB non raffinée de petits producteurs français" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <p className="about_text">
                        Greedies sélectionne rigoureusement tous les ingrédients utilisés lors de la fabrication: le chocolat Valrhona, les fruits secs AB, le beurre extra-fin AOP, les fruits confits du confiseur Lilamand, les oeufs AB, le limoncello de Via Manzoni, la vanille bourbon de Madagascar, la fleur de sel de Guérande AB non raffinée... que le meilleur!<br/>
                        Tout en respectant scrupuleusement les règles d'hygiène.
                    </p> 
                </article>
            </motion.main>

            <Footer />
        </>
    )
    
}

export default About