import React from 'react';
import Footer from './Footer';
import Product from './Product';
import { motion } from 'framer-motion'


const Shop = ({products})=> {

    //Animation de transition des pages
    const variants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.5 },
            x: -800
        },
        visible: {
            opacity: 1,
            x: 0
        },
        exit: {
            opacity: 0.4,
            transition: { duration: 0.3},
            x: 800
        }
    }

    const transition = {
        ease: [.03,.87,.73,.9],
        duration: .6
    }
    
    return(
        <>
            <motion.main 
                className="shop"
                initial="initial" 
                animate="visible" 
                exit="exit" 
                variants={variants}
                transition={transition}
            >
                <h1 className="shop_title">Boutique</h1>
                {
                    products.map((product) => <Product product={product} key={product.id} /> )
                }
            </motion.main>
            <Footer />
        </>
    )
}


export default Shop