import React from 'react';
import {NavLink} from 'react-router-dom'


const Navbar = () => (
    <header className="navigation">
        <nav className="navigation_content">
            <ul>
                <li className="navigation_link">
                    <NavLink className="nav_link" activeClassName="active" to="/about">accueil</NavLink>
                </li>
                <li className="navigation_link">
                    <NavLink className="nav_link" activeClassName="active" to="/shop">boutique</NavLink>
                </li>
                <li className="navigation_link">
                    <NavLink className="nav_link" activeClassName="active" to="/contact">contact</NavLink>
                </li>
            </ul>
        </nav>
    </header>
)


export default Navbar