import React from 'react';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion'


const Home = () => {
    
        const variants = {
            initial: {
                opacity: 0,
                transition: { duration: 0.5 },
                x: -100
            },
            visible: {
                opacity: 1,
                x: 0
            },
            exit: {
                opacity: 0,
                transition: { duration: 0.3},
                x: 800
            }
        }

        //Animation sur les images
        const imgAnim = {
            initial: {
                opacity: 0,
                x: -700,
            },
            visible: {
                opacity: 1,
                x: 0,
            }
        }

        const transition = {
            ease: [.03,.87,.73,.9],
            duration: .6
        }

    return(

        <motion.main 
            className="main" 
            initial="visible" 
            animate="visible" 
            exit="exit" 
            variants={variants}
            transition={transition}
        >
            <div className="main_content">
                <motion.img 
                    className="main_img" 
                    src="./images/main-sapin-noel-greedies.jpg" 
                    alt="Sapin de Noël" 
                    initial="initial" 
                    animate="visible"  
                    variants={imgAnim}
                    transition={{ duration: 0.9 }}
                />
                <div className="main_text">
                    <p className="main_catch">Découvrez les gourmandises de Noël</p>
                    <h1 className="main_title">Greedies</h1>
                    <Link to="/shop" className="btn_main">Boutique éphémère</Link> 
                </div>  
            </div>
        </motion.main>
    )
    
}

export default Home