import React from 'react'
import NavBar from './NavBar'
import Home from './Home'
import About from './About'
import Shop from './Shop'
import Contact from './Contact'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import '../css/App.scss'
import initialData from '../initialData'
import { AnimatePresence } from 'framer-motion'


class App extends React.Component {
    render(){
        return(
            <Router>
                <NavBar />
                <AnimatePresence>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/shop" render={(props) => <Shop {...props} products={initialData} />} />
                        <Route exact path="/contact" component={Contact} />
                        <Redirect to="/" />
                    </Switch>
                </AnimatePresence>
            </Router>
            
        )
    }
}

export default App