let initialData = [
    {id:'eon', category: 'Etoile de Noël', name:'Orangettes au chocolat noir', image: './images/orangette-chocolat-noir_ballotin-noel-etoile-greedies.jpg', alt: 'Orangettes au chocolat noir dans l\'Etoile de Noël de Greedies', description: 'La boîte Etoile est composée de 100g d\'orangettes au chocolat noir (des lamelles d\'oranges confites de Lilamand enrobées de chocolat noir Valrhona Guanaja 70%)', price: '11', separation: true, position: ""},
    {id:'eol', category: 'Etoile de Noël', name:'Orangettes au chocolat au lait', image: './images/orangette-chocolat-lait_ballotin-noel-etoile-greedies.jpg', alt: 'Orangettes au chocolat au lait dans l\'Etoile de Noël de Greedies', description: 'La boîte Etoile est composée de 100g d\'orangettes au chocolat au lait (des lamelles d\'oranges confites de Lilamand enrobées de chocolat au lait Valrhona Equatoriale 35%)', price: '11', separation: true, position: "article_left"},
    {id:'ea', category: 'Etoile de Noël', name:'Assortiment aux chocolats noir, lait, blond, blanc et fraise', image: './images/assortiment-chocolat_etoile-noel-greedies.jpg', alt: 'Assortiment de bonbons chocolats de Greedies dans l\'Etoile de Noël', description: 'La boîte Etoile est composée de 120g d\'un assortiment de bonbons chocolat (orangettes, citronettes, mendiants, coeur caramel fleur de sel, florentins, truffes; aux chocolats Valrhona : noir Guanaja 70%, lait Equatoriale 35%, blond Dulcey, blanc Ivoire, fraise)', price: '10', separation: true, position: ""},
    {id:'tc', category: 'Boule de Noël', name:'Coco et chocolat blanc', image: './images/truffe-chocolat-blanc-coco_boule-coco_sapin-noel-greedies.jpg', alt: 'Truffe coco et chocolat blanc dans la boule de Noël de Greedies', description: 'La boule est commposée de 150g de truffes à la noix de coco et au chocolat blanc Valrhona Ivoire', price: '10', separation: true, position: "article_left"},
    {id:'tr', category: 'Boule de Noël', name:'Rocher', image: './images/truffe-chocolat-lait-praline_boule-rocher_decoration-sapin-noel-greedies.jpg', alt: 'Truffe rocher au praliné et chocolat au lait dans la boule de Noël de Greedies', description: 'La boule est commposée de 150g de truffes au praliné 60% noisettes et au chocolat au lait Valrhona Equatoriale 35%', price: '11', separation: true, position: ""},
    {id:'bl',  category: 'Pot de Noël', name:'Babas au limoncello', image: './images/baba-limoncello_noel-greedies.jpg', alt: 'Babas au limoncello de Noël fabriqué par Greedies', description: 'Le pot est composé de babas au limoncello Via Manzoni', price: '8', separation: false, position: "article_left"}
]


export default initialData