import React from 'react';
import {Link} from 'react-router-dom'
import { AiOutlineShopping, AiOutlineQuestionCircle } from 'react-icons/ai'


const Footer = () => {

    return(
        <footer className="footer">
            <div className="footer_content">
                <Link className="footer_logo" to="/">
                   <img className="footer_img" src="./images/greedies-authentique-et-moelleux_logo-blanc.png" alt="Logo Greedies"/>
                </Link>
                <Link className="footer_icon" to="/shop">
                    <AiOutlineShopping className="footer_svg"/>
                </Link>
                <Link className="footer_icon" to="/contact">
                    <AiOutlineQuestionCircle className="footer_svg"/>
                </Link>
            </div>
            <small className="footer_text">Copyright &copy; 2021 Greedies, authentique et moelleux.</small>
        </footer>
    )

}




export default Footer