import React from 'react';
import Footer from './Footer';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion'


const Contact = () => {

    //Animation de transition des pages
    const variants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.5 },
            x: -800
        },
        visible: {
            opacity: 1,
            x: 0
        },
        exit: {
            opacity: 0.4,
            transition: { duration: 0.3},
            x: 800
        }
    }

    //Animation sur les images
    const imgAnim = {
        initial: {
            opacity: 0.4,
            x: -100,
        },
        visible: {
            opacity: 1,
            x: 0,
        }
    }

    //Transition pour l'animation des pages
    const transition = {
        ease: [.03,.87,.73,.9],
        duration: .6
    }

    return(
        <>
            <motion.main 
                className="contact"
                initial="initial" 
                animate="visible" 
                exit="exit" 
                variants={variants}
                transition={transition}
            >
                <h1 className="contact_title">Contact</h1>
                <article className="contact_content">
                    <motion.img 
                        className="contact_img" 
                        src="./images/orangette-chocolat-noir_etoile-noel-greedies.jpg" 
                        alt="Orangettes au chocolat noir de Noël de Greedies" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <div className="contact_text">
                        <p>Pour passer commande, envoyez :</p>
                        <ul>
                            <li>la quantité de produits souhaités,</li>
                            <li>suivi du nom du produit.</li>
                        </ul>
                        <p>Et envoyez votre commande par sms ou par mail :</p>       
                        <ul>
                            <li>Tél : <a className="contact_link" href="tel:+33603576363">06 03 57 63 63</a></li>
                            <li>Mail : <a className="contact_link" href="mailto:greedies.fabriquedebiscuits@gmail.com">greedies.fabriquedebiscuits@gmail.com</a></li>
                        </ul>
                        <Link to="/shop" className="btn_contact">Boutique éphémère</Link> 
                    </div>    
                </article>
                <article className="contact_content article_left">
                    <motion.img 
                        className="contact_img" 
                        src="./images/truffe-chocolat-lait-praline_boule-rocher_noel-greedies.jpg" 
                        alt="Truffes rocher au chocolat au lait et praliné de Greedies de Noël" 
                        initial="initial" 
                        animate="visible"  
                        variants={imgAnim}
                        transition={{ duration: 0.6 }}
                    />
                    <p className="contact_text">
                        Vous pouvez passer commande jusqu'au 26 novembre 2021, dernier délai.<br/>
                        Vous pourrez récupérer vos commandes du 17 au 19 décembre 2021 à mon domicile (pour d'autres options, voir directement lors de la commande).<br/>
                        Passez vos commandes rapidement, l'offre est limitée en quantité !<br/>
                        Greedies vous remercie pour votre confiance et vous souhaite de bonnes fêtes de fin d'année.
                    </p> 
                </article>
            </motion.main>
            <Footer />
        </>
    )
    
}


export default Contact